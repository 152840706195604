import isWindowDefined from "./isWindowDefined";

const scrollToItem = ({ item, plus = 0, minus = 0 }) => {
  if (!!item && isWindowDefined())
    window.scrollTo({
      top: item.getBoundingClientRect().top + window.pageYOffset + plus - minus,
      behavior: "smooth"
    });
};

export default scrollToItem;
